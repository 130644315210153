body {
  margin: 0;
  padding: 0;
  overflow-y: 'scroll';
  scroll-behavior: 'smooth';
  overflow-x: hidden;
  width: 100%;
}

.MuiTab-root:hover {
  color: gold;
}

img {
  width: 100%;
}

.MuiTab-root {
  min-width: 0px !important;
}

.MuiAppBar-colorDefault {
  color: #f5f5f5 !important;
  background-color: #113359 !important;
  }

.MuiAccordion-root img {
  height: 400px;
  width: auto;
}

.MuiContainer-root {
  margin-bottom: 1em;
}